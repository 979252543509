import React from 'react'
import $ from 'jquery'
import { inputFieldProperties } from '../../helpers/InputFieldProperties';

export default ({
    input,
    type,
    className,
    help,
    info,
    column,
    disabled = false,
    meta: { touched, error, warning, initial }
}) => {
    const { name } = input;

    if (touched && error) {
        $(`#${name}`).addClass('input-error animated shake');
    }

    if (touched && !error) {
        $(`#${name}`).removeClass('input-error animated shake');
    }

    return (
        <div className={column || "col-xl-4"}>
            <div className="submit-field">
                {!touched && <h5 id="inputLabel">
                    {inputFieldProperties[name].label}
                    {help &&
                        <span className="tool" data-tip={`${info}`} >
                            <i class="help-icon" data-tippy-placement="right"></i>
                        </span>
                    }
                </h5>}

                {(touched && !error) && <h5 id="inputLabel">
                    {inputFieldProperties[name].label}
                    {help &&
                        <span className="tool" data-tip={`${info}`} >
                            <i class="help-icon" data-tippy-placement="right"></i>
                        </span>
                    }
                </h5>}

                {touched &&
                    ((error && <div className="text-error">{error}</div>) ||
                        (warning && <span>{warning}</span>))}

                
                <input
                    {...input}
                    placeholder={inputFieldProperties[name].placeholder}
                    type={type}
                    id={name}
                    className={className}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

