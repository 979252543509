import useStore from './useStore';
import { setModalVisibility } from '../reducers/controls';

export default () => {
    const { dispatch } = useStore();
    
    const showLoginModalWindow  = () => {
        dispatch(
            setModalVisibility({
                isOpen: true,
                modalTriggerOwner: 'login',
            })
        )
    }

    return {
        dispatch,
        showLoginModalWindow
    }
};
