import { mapAuthToProps } from '../lib/encorders'
import useStore from './useStore';

export default () => {
    const { state, dispatch } = useStore();

    return {
        dispatch,
        accessToken: state.auth.isAuthenticated === true ? state.auth.currentUser.accessToken: null,
        isAuthenticated: state.auth.isAuthenticated,
        currentUser: state.auth.isAuthenticated === true ? mapAuthToProps(state) : null    
    };
};
