import { useEffect, useState } from 'react';
import useStore from './useStore';
import useAuth from './useAuth';
import { selectReceiverProfile, sendInterest } from '../reducers/interest'
import { fetchUserProfiles } from '../reducers/profile'
import { sendRequest, changeRequestState } from '../reducers/accessRequest'

export default (parent) => {
    const { state, dispatch } = useStore();
    const { currentUser } = useAuth();
 
    const { profile: { loading, allUserProfiles }, interest } = state;
    
    const hasItBeenAlreadySent = (props) => {
        if(parent === 'SEND_REQUEST_TO_ACCESS') {
            const { id, sent_requests } = props;
            const { interestReceiver } = interest;
            if(sent_requests.length === 0) {
                return false
            } 

            return sent_requests.filter(request => {
                return (request.receiver_profile_id === interestReceiver.id ) && (id === request.sender_profile_id)
            }).length === 0 ? false : true 
        }

        if(parent === 'SEND_INTEREST') {
            const { id, sent_interests } = props;
            const { interestReceiver } = interest;
            if(sent_interests.length === 0) {
                return false
            } 

            return sent_interests.filter(request => {
                return (request.receiver_profile_id === interestReceiver.id ) && (id === request.sender_profile_id)
            }).length === 0 ? false : true 
        }
    };

    useEffect(() => {
        dispatch(fetchUserProfiles(currentUser.sub))
    }, [])

    const selectReceiverProfileHandler = ({ id }) => {
        dispatch(selectReceiverProfile(id));
    }

    const sendRequestToAccessHandler = ({ id }) => {
        if(parent === 'SEND_REQUEST_TO_ACCESS') {
            return dispatch(sendRequest(id));
        }

        if(parent === 'SEND_INTEREST') {
            dispatch(sendInterest({ id }));
        }       
    }

    const acceptOrRejectRequestHandler = (params) => {
        dispatch(changeRequestState(params));
    }

    return {
        dispatch,
        loading, 
        allUserProfiles,
        hasItBeenAlreadySent,
        selectReceiverProfileHandler,
        acceptOrRejectRequestHandler,
        sendRequestToAccessHandler
    }
};
