import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
 
const Loading = (props) => {
    const { isActive } = props;
    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                {/* <p>Some content or children or something.</p> */}
            </LoadingOverlay>
        </>
    );
};

export default Loading;