import { inputFieldProperties } from "../helpers/InputFieldProperties"

export const required = (value, fields, fun, key) =>  {
    try {
        return (value || typeof value === 'number' ? undefined : `${inputFieldProperties[key].label} field is required`)
    } catch (error) {
        console.error(error)
    }
}

export const requiredUsernameEmail = value => (value || typeof value === 'number'  ? undefined : 'Please enter your username or email address')

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined

export const maxLength15 = maxLength(15)

export const minLength = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined

export const minLength2 = minLength(2)

export const number = value =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const string = value =>
    value && /[^A-Za-z ]+/g.test(value) ? 'Invalid charater has been entered' : undefined

export const minValue = min => value =>
    value && value < min ? `Must be at least ${min}` : undefined

export const minValue13 = minValue(13)

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : undefined

export const tooYoung = value =>
    value && value < 13
        ? 'You do not meet the minimum age requirement!'
        : undefined

export const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined


export const phoneNumber = value =>
    value && /^[\+]?[^0-9]+$/i.test(value)
        ? 'Invalid phone number'
        : undefined

export const matchPassword = (value, { repeatPassword, password}) => {
    return value && (String(password) !== String(repeatPassword)) ? "Passwords are not match " : undefined
}
    
export const passwordMinLength = value =>
    value && value.length < 8 ? `Must be 8 characters or more` : undefined




    // country_of_birth: {
    //     label: "AAAAAAAA",
    //     placeholder: "AAAAAAAA"
    // },

    // import {
    //     getFormValues,
    //     getFormSyncErrors,
    //     getFormSubmitErrors,
    //     isDirty,
    //     isPristine,
    //     isValid,
    //     isInvalid
    //   } from 'redux-form'
      
    //   MyComponent = connect(
    //     state => ({
    //       values: getFormValues('myForm')(state),
    //       syncErrors: getFormSyncErrors('myForm')(state),
    //       submitErrors: getFormSubmitErrors('myForm')(state),
    //       dirty: isDirty('myForm')(state),
    //       pristine: isPristine('myForm')(state),
    //       valid: isValid('myForm')(state),
    //       invalid: isInvalid('myForm')(state)
    //     })
    //   )(MyComponent)



    // https://www.pluralsight.com/guides/different-ways-to-dispatch-actions-with-redux