import { useState, useEffect } from 'react'
import useStore from './useStore'
import useAuth from './useAuth'
import RESTClient from '../client/RESTClient'
import { NOTIFICATION_API } from '../client/urls'
import { notification } from '../lib/notify'
import { getNotificationHandler } from '../reducers/notification'

export default (uri) => {
    const { state, dispatch } = useStore();
    const { notification: { loading, notifications}} = state;
    const {
        accessToken,
        currentUser: { sub },
    } = useAuth()
    const [status, setStatus] = useState('');


    useEffect(() => {
        dispatch(getNotificationHandler(sub));
    }, [uri, status]);

    const makeAsReadHandler = async (id, value) => {
        const notificationParams = {
            action: 'update',
            notification_id: id,
            value
        }
    
        const { data, status, message } = await RESTClient(NOTIFICATION_API, notificationParams, accessToken);
        setStatus(data)
        if(status === 'error') {
            notification({
                type: 'danger',
                message: 'Something went wrong please try again'
            }); return true;
        }
        return true;
    };

    return {
        dispatch,
        loading,
        notifications,
        makeAsReadHandler
    }
}
