import { useState, useEffect, useCallback } from 'react';
import useStore from './useStore';
import {
    fetchUserShortlist,
    createOrDeleteShortlist,
} from '../reducers/shortlist'

const isProfileExist = (shortlist, profileId) => {
    return shortlist.some(list => list.profile.id === profileId);
}

export default  (id = null) => {
    const { state, dispatch } = useStore();
    const [isExist, setIsExist] = useState(false);
    const { shortlist: { loding, shortlist }} = state;

    const execute = useCallback(() => {
        if(id !== null) { // Id is only required when we use this hooks for shortlish button
            setIsExist(isProfileExist(shortlist, id))
        }
    }, [shortlist.length]);
  
    useEffect(() => {
        execute();
    }, [shortlist.length]);
    
    return {
        dispatch,
        loding,
        isExist,
        shortlist ,
        createOrDeleteShortlist,
        fetchUserShortlist
    };
};
