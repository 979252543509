import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link, navigate } from '@reach/router'
import { connect } from 'react-redux'
import inputField from '../InputFields/Input'
import urls from '../../helpers/urls'
import { signIn } from '../../reducers/auth'
import {
    required,
    requiredUsernameEmail
} from '../../lib/validationAndWarning'

const LoginForm = ({ signIn, handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit(signIn)}>
            <div id="sign-in-dialog" className="zoom-anim-dialog  dialog-with-tabs">
                <div className="sign-in-form">
                    <ul className="popup-tabs-nav">
                        <li>
                            <a href="#login">Log In</a>
                        </li>
                    </ul>
                    <div className="popup-tabs-container">
                        <div className="popup-tab-content" id="login">
                            <div className="welcome-text">
                                <h3>We're glad to see you again!</h3>
                                <span>
                                    Don't have an account?
                                    <Link
                                        to="/app/signup"
                                        className="register-tab"
                                        style={{ marginLeft: 10 }}
                                    >
                                        Sign Up
                                    </Link>
                                </span>
                            </div>

                            <div className="row">
                                <Field
                                    name="usernameOrEmail"
                                    type="text"
                                    component={inputField}
                                    className="with-border"
                                    column="col-xl-12"
                                    validate={[requiredUsernameEmail]}
                                />

                                <Field
                                    name="password"
                                    type="password"
                                    component={inputField}
                                    className="with-border"
                                    column="col-xl-12"
                                    validate={[required]}
                                />
                            </div>
                            <Link to="/app/forgot-password" className="forgot-password">
                                Forgot Password?
                            </Link>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <Link
                                        to="/app/signup"
                                        className="button full-width button-sliding-icon ripple-effect margin-top-10"
                                    >
                                        Sign Up
                                        <i className="icon-material-outline-arrow-right-alt" />
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <button
                                        className="button full-width button-sliding-icon ripple-effect margin-top-10"
                                        type="submit"
                                    >
                                        Log In
                                        <i className="icon-material-outline-arrow-right-alt" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

const InilizeLoginForm = reduxForm({
    form: 'loginForm'
})(LoginForm)

const mapStateToProps = state => ({
    signInError: state.auth.error
})

const mapDispatchToProps = {
    signIn
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InilizeLoginForm)
