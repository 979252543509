import { useState, useEffect , useCallback} from 'react'
import useStore from './useStore'
import useAuth from './useAuth'
import RESTClient from '../client/RESTClient'
import { KEYCLOAK_AUTH_USER_API } from '../client/urls'

export default () => {
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);
    const [currentUserData, setCurrentUserData] = useState({});

    const {
        accessToken,
    } = useAuth();

    const fetch = useCallback(async () => {
        setLoading(true)
        const notificationParams = {
            action: 'current-user-details',
        }

        const { data, status, message } = await RESTClient(KEYCLOAK_AUTH_USER_API, notificationParams, accessToken);
        
        if (status === 'error') {
            console.error(message);
            return true;
        }
        
        setCurrentUserData(data);
        setLoading(false)
        return true;
    }, [])

    useEffect(() => {
        fetch();
    }, []);

    return {
        dispatch,
        loading,
        currentUserData
    }
}
