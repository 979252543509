import React from 'react';

const moreStyle = {
    marginTop: 30
}

const NavigationBar = ({ step, details}) => {

    if(!details) {
        return <div/>
    }

    return (
        <div className="container">
            <div className="dashboard-headline" >
                <nav id="breadcrumbs" className="dark">
                    <ul>
                        <li><a href="#">Registration</a></li>
                        <li><a href="#">{step}</a></li>
                        <li>{details}</li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default NavigationBar;