import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { setModalVisibility } from '../../reducers/controls'

const ModalWrapper = ({
    modalOwner,
    properties: { isOpen, modalTriggerOwner },
    setModalVisibility,
    children,
}) => {
    const onCloseModal = () => {
        setModalVisibility(false)
    }

    return (
        <Modal
            open={modalOwner === modalTriggerOwner && isOpen}
            onClose={() => onCloseModal()}
            center
        >
            {children}
        </Modal>
    )
}

const mapStateToProps = state => ({
    properties: state.controls.openModal,
})

const mapDispatchToProps = {
    setModalVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper)
// https://codesandbox.io/s/react-responsive-modal-inh28
