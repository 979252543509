import React, { Fragment } from 'react'
import { useAuth, useShortlist } from '../../hooks/useHooks'

export default ({ isLargeButton, profile }) => {
    const { id } = profile;
    const { currentUser } = useAuth();
    const {
        dispatch,
        isExist,
        createOrDeleteShortlist,
    } = useShortlist(id);

    return (
        currentUser !== null &&
        currentUser.sub !== profile.user_id && (
            <Fragment>
                {!isLargeButton ? (
                    <span
                        className={`bookmark-icon ${isExist && 'bookmarked'}`}
                        onClick={event => {
                            event.preventDefault()
                            dispatch(createOrDeleteShortlist(profile, 'create'))
                        }}
                    />
                ) : (
                        <button
                            className="button big full-width "
                            onClick={event => {
                                event.preventDefault()
                                dispatch(createOrDeleteShortlist(profile, 'create'))
                            }}
                        >
                            {isExist ? (
                                <>
                                    Remove From Shortlist{' '}
                                    <i className="icon-line-awesome-heart"></i>
                                </>
                            ) : (
                                    <>
                                        Add To Shortlist <i className="icon-feather-heart"></i>
                                    </>
                                )}
                        </button>
                    )}
            </Fragment>
        )
    )
};
