import { useState, useEffect, useCallback } from 'react';
import useStore from './useStore';
import useAuth from './useAuth';
import { fetchUserProfiles } from '../reducers/profile'

export default  () => {
    const { state, dispatch } = useStore();
    const { currentUser } = useAuth();
    const { profile: { loading, allUserProfiles } } = state;

    const execute = useCallback(() => {
        dispatch(fetchUserProfiles(currentUser.sub));
    }, []);
  
    useEffect(() => {
        execute();
    }, []);
    
    return {
        dispatch, 
        loading,
        userProfiles: allUserProfiles.profiles
    }
};
