export const inputFieldProperties = {
    usernameOrEmail: {
        label: "Username Or Email",
        placeholder:"Enter your username or password"
    },
    password: {
        label: "Password",
        placeholder: "Enter your password"
    },
    profile_created_by: {
        label: "Profile created by",
        placeholder:"Profile Created by"
    },
    first_name: {
        label: "First Name",
        placeholder: "Enter your first name"
    },
    last_name: {
        label: "Last Name",
        placeholder: "Enter your last name"
    },
    dob: {
        label: "Date of birth",
        placeholder: "Select your date of birth"
    },
    gender: {
        label: "Gender",
        placeholder: "Select your gender"
    },
    religion: {
        label: "Religion",
        placeholder: "Select your religion"
    },
    chevvai_dosham: {
        label: "Do you have Chevvai dosham",
        placeholder: "Select Yer or No"
    },
    mother_tongue: {
        label: "Mother Tongue",
        placeholder: "Select your Mother Tongue"
    },
    primary_language: {
        label: "Primary Language",
        placeholder: "Select your primary language"
    },
    caste: {
        label: "Caste",
        placeholder: "Select your Caste"
    },
    marital_status: {
        label: "Meritial Status",
        placeholder: "Select your Meritial Status"
    },
    children: {
        label: "Do you have children ?",
        placeholder: "Select your number of children"
    },
    number_of_children: {
        label: "Number of children",
        placeholder: "Select number of children"
    },
    living_city: {
        label: "Living City",
        placeholder: "Enter your city"
    },
    living_state: {
        label: "Living State",
        placeholder: "Enter your state"
    },
    living_country: {
        label: "Living Country",
        placeholder: "Where do you live ?"
    },
    residence_status: {
        label: "Residence Status",
        placeholder: "Select your Residence Status"
    },
    nationality: {
        label: "Nationality",
        placeholder: "Select your Nationality"
    },
    country_of_birth: {
        label: "Country of birth",
        placeholder: "Select your country of birth"
    },
    education_level: {
        label: "Education Level",
        placeholder: "Select your education level"
    },
    education_field: {
        label: "Education Field",
        placeholder: "Select your education field"
    },
    occupation: {
        label: "Occupation",
        placeholder: "Select your occupation"
    },
    income_currency: {
        label: "Income Currency",
        placeholder: "Select your income currency"
    },
    annual_income: {
        label: "Annual Income",
        placeholder: "Select your annual income"
    },
    body_type: {
        label: "Body Type",
        placeholder: "Select your body type"
    },
    height: {
        label: "height",
        placeholder: "Select your height"
    },
    skin_tone: {
        label: "Skin Tone",
        placeholder: "Select your skin tone"
    },
    food_habit: {
        label: "Food Habit",
        placeholder: "Select your Food habit"
    },
    smoking_habit: {
        label: "Smoking Habit",
        placeholder: "Select your smoking habit"
    },
    drinking_habit: {
        label: "Drinking Habit",
        placeholder: "Select your drinking habit"
    },
    contact_email: {
        label: "Personal contact E-mail(Optional)",
        placeholder: "Enter your personal contact E-mail"
    },
    contact_number: {
        label: "Personal contact number(Optional)",
        placeholder: "Enter your personal contact number"
    },
    father_full_name: {
        label: "Father's full name",
        placeholder: "Enter your fathers name"
    },
    mother_full_name: {
        label: "Mother's full name",
        placeholder: "Enter your mother's name"
    },
    father_status: {
        label: "Father's status",
        placeholder: "Enter father's status"
    },
    mother_status: {
        label: "Mother's status",
        placeholder: "Enter mother's status"
    },
    father_native_place: {
        label: "Father's native place",
        placeholder: "Father's native place"
    },
    mother_native_place: {
        label: "Mother's native place",
        placeholder: "Enter mother's native place"
    },
    unmarried_male_siblings: {
        label: "Unmarried male sibling",
        placeholder: "Select number of unmarried male siblings"
    },
    unmarried_female_siblings: {
        label: "Unmarried female siblings",
        placeholder: "Select number of unmarried female siblings"
    },
    married_male_siblings: {
        label: "Married male siblings",
        placeholder: "Select number of unmarried female siblings"
    },
    married_female_siblings: {
        label: "Married female siblings",
        placeholder: "Select number of unmarried female siblings"
    },
    family_living_country: {
        label: "Living Country",
        placeholder: "Where does you your family ?"
    },
    family_status: {
        label: "Family Status",
        placeholder: "Select your family status"
    },
    family_value: {
        label: "Family Values",
        placeholder: "Select your family value"
    },
    about_family: {
        label: "About your family",
        placeholder: "Say something about your family"
    },
    country: {
        label: "Country",
        placeholder: "Select your living country"
    },
    username: {
        label: "Username",
        placeholder: "Your username"
    },
    email: {
        label: "Email",
        placeholder: "Enter your email"
    },
    name: {
        label: "Name",
        placeholder: "Enter your name"
    },
    firstName: {
        label: "First Name",
        placeholder: "Enter your first name"
    },
    lastName: {
        label: "Last Name",
        placeholder: "Enter your last name"
    },
    phoneNumber: {
        label: "Phone Number",
        placeholder: "Enter your phone number"
    },
    phone: {
        label: "Phone Number",
        placeholder: "Enter your phone number"
    },
    oldPassword: {
        label: "Old Password",
        placeholder: "Enter your old password"
    },
    newPassword: {
        label: "New Password",
        placeholder: "Enter your new password"
    },
    confirmPassword: {
        label: "Confirm Password",
        placeholder: "Enter your confirm password"
    },
    subject: {
        label: "Subject",
        placeholder: "Enter your subject"
    },
    message: {
        label: "Message",
        placeholder: "Enter your message"
    },
    emailVerificationCode: {
        label: "Email Verification Code",
        placeholder: "Enter your email verification code"
    },
    phoneVerificationCode: {
        label: "Phone Verification Code",
        placeholder: "Enter your phone verification code"
    },
    confirmationDetails: {
        label: "Profile reference number",
        placeholder: "Enter the profile reference number"
    },
};