import { useEffect, useState } from 'react'
import _ from 'lodash'

import searchProfiles from '../graphql/queries/searchProfiles'
import useStore from './useStore'

const profileInitialData = {
    profile_count_based_country: [],
    profile_count_based_gender: [],
    profile_count_based_religion: [],
    profile_count_based_status: [],
    profiles: []
};

export default (scrollPosition) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)
    const [profiles, setProfiles] = useState(profileInitialData)
    const [dataLength, setDatalength] = useState(0)
    const [hasMore, setHasMore] = useState(false)
    const [pageNumber, setPageNumber] = useState(0)


    const { state, } = useStore();
    const {
        form: {
            profileSerchForm: { values }
        },
    } = state;

    const {
        country,
        gender,
        maritalStatus,
        religion,
        ageRange,
    } = values;


    // const lastSearchResultRef = useCallback(node => {
    //     if (loading) return
    //     if (observer.current) observer.current.disconnect()
    //     observer.current = new IntersectionObserver(entries => {
    //         console.log('------------------------------------');
    //         console.log(entries[0].isIntersecting);
    //         console.log(hasMore);
    //         console.log('------------------------------------');
    //         if (entries[0].isIntersecting && hasMore) {
    //             setPageNumber(prevPageNumber => prevPageNumber + 1)
    //         }
    //     })
    //     if (node) observer.current.observe(node)
    // }, [loading, hasMore])

    useEffect(() => {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
    }, [scrollPosition && dataLength !== 0])

    useEffect(() => {
        setProfiles(profileInitialData);
        setPageNumber(0);
        setHasMore(false);
    }, [values]);


    let mutatedValues = {
        startAge: ageRange[0],
        endAge: ageRange[1],
        gender: gender === 'null' ? null : gender,
        religion: (religion === null) || religion.length === 0 ? null : `{${religion.join(', ')}}`,
        country: (country === null) || country.length === 0 ? null : `{${country.join(', ')}}`,
        maritalStatus:  (maritalStatus === null) || maritalStatus.length === 0 ? null : `{${maritalStatus.join(', ')}}`,
        offset: pageNumber,
        limit: 10
    };

    const fetchData = async () => {
        const { data, loading, error } = await searchProfiles(mutatedValues);
        
        if(error) {
            setError(error);
        }

        if(!loading) {
            setDatalength(data.profiles.length)
            setProfiles(prevResult => {
                return {
                    ...data,
                    profiles: _.uniqWith([...prevResult.profiles, ...data.profiles], _.isEqual)
                    
                }
            })
            setHasMore(data.profiles.length > 0)
            setLoading(loading);
        }
    }

    useEffect(() => {
        fetchData();
    }, [values, pageNumber]);

    return { loading, error, allProfiles: profiles, hasMore }
}

