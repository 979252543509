import { useEffect, useState } from 'react';

export default () => {
    const [scrollState, setScrollState] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, [])

    const handleScroll = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            setScrollState(true)
        } else {
            setScrollState(false)
        }
    }

    return { scrollState };
};

