import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import Logo from '../../assets/images/logo/lankanwedding_logo_vertical.png'
import { setModalVisibility } from '../../reducers/controls'
import { logout } from '../../reducers/auth'
import { isUserVerified } from '../../lib/resolver'



const Headder = ({ setModalVisibility, logout, auth }) => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    
    const navContent = () => {
        return (
            <>
                <li>
                    <Link to="/" className="current" onClick={() => setShowMobileMenu(false)}>
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="#plans" className="current" onClick={() => setShowMobileMenu(false)}>
                        Plans
                    </Link>
                </li>
                <li>
                    <Link to="#contact-us" className="current" onClick={() => setShowMobileMenu(false)}>
                        Contact Us
                    </Link>
                </li>
            </>
        );
    }

    return (
        <div>
            <header id="header-container" className="fullwidth transparent sticky-header">
                <div id="header">
                    <div className="container">
                        <div className="left-side">
                            <div id="logo">
                                <Link to="/">
                                    <img
                                        src={Logo}
                                        alt="lankanwedding logo"
                                    />
                                </Link>
                            </div>
                            <nav id="navigation" className="mmenu-init mm-menu mm-offcanvas mm-opened">
                                <ul id="responsive">
                                    {navContent()}
                                </ul>
                            </nav>
                            <div className="clearfix" />
                        </div>
                        <div className="right-side">

                            {isUserVerified(auth) && (
                                <div className="header-widget">
                                    <Link
                                        to="/app/dashboard"
                                        className="popup-with-zoom-anim log-in-button"
                                    >
                                        <i className="icon-material-outline-dashboard" />
                                        <span>Dashboard</span>
                                    </Link>
                                </div>
                            )}

                            {auth.isAuthenticated && (
                                <div className="header-widget">
                                    <Link
                                        to={''}
                                        className="popup-with-zoom-anim log-in-button"
                                        onClick={() => logout(auth)}
                                    >
                                        <i className="icon-line-awesome-sign-out" />
                                        <span>Logout</span>
                                    </Link>
                                </div>
                            )}

                            {!auth.isAuthenticated && (
                                <div className="header-widget">
                                    <a
                                        href="#"
                                        className="popup-with-zoom-anim log-in-button"
                                        onClick={() =>
                                            setModalVisibility({
                                                isOpen: true,
                                                modalTriggerOwner: 'login',
                                            })
                                        }
                                    >
                                        <i className="icon-feather-unlock" />
                                        <span>Log In / Register</span>
                                    </a>
                                </div>
                            )}

                            <span className="mmenu-trigger">
                                <button className="hamburger hamburger--collapse" type="button">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner" onClick={() => {
                                            setShowMobileMenu( showMobileMenu ? false : true)
                                        }}/>
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={`mobile-nav ${showMobileMenu && 'active'}`} >
                    {navContent()}
                </div>
            </header>
            <div className="clearfix" />
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
})

const mapDispatchToProps = {
    setModalVisibility,
    logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(Headder)
