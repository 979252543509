import React from 'react'
import { Link } from '@reach/router'

export default () => {
    return (
        <footer id="footer">
            <div className="footer-bottom-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="footer-simple-link">
                                <Link to="/app/about-us">About Us</Link>
                                <Link to="/app/contact-us">Contact Us</Link>
                                <Link to="/app/terms-and-conditions">Terms & Conditions</Link>
                                <Link to="/app/privacy">Privacy Policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            © 2020 <strong>Lankan Wedding</strong>. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
