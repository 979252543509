import React from 'react'
import $ from 'jquery'

const renderField = ({
    input,
    label,
    type,
    className,
    placeholder,
    help,
    info,
    column,
    disabled = false,
    meta: { touched, error, warning, initial }
}) => {
    const { name } = input;
    if (touched && error) {
        $(`#${name}`).addClass('input-error animated shake')
    }

    if (touched && !error) {
        $(`#${name}`).removeClass('input-error animated shake')
    }

    return (
        <div className="col-xl-12">
            <div className="submit-field">
                {!touched && (
                    <h5 id="inputLabel">
                        {label}
                        {help && (
                            <span className="tool" data-tip={`${info}`}>
                                <i class="help-icon" data-tippy-placement="right"></i>
                            </span>
                        )}
                    </h5>
                )}

                {touched && !error && (
                    <h5 id="inputLabel">
                        {label}
                        {help && (
                            <span className="tool" data-tip={`${info}`}>
                                <i class="help-icon" data-tippy-placement="right"></i>
                            </span>
                        )}
                    </h5>
                )}

                {touched &&
                    ((error && <div className="text-error">{error}</div>) ||
                        (warning && <span>{warning}</span>))}
                <textarea {...input} id={name} cols={30} rows={5} className={className} placeholder={placeholder}/>
            </div>
        </div>
    )
}
export default renderField
