import React from 'react'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const  Notifiacation = () => {
    return (
        <div>
            <div className="app-container">
                <ReactNotification />
            </div>
        </div>
    )
}

export default Notifiacation;