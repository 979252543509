import { useState, useEffect, useContext } from 'react'
import { ReactReduxContext } from 'react-redux'

export default () => {
  const { store } = useContext(ReactReduxContext)
  const { getState, dispatch, subscribe } = store;
  const [state, setState] = useState(getState())
  useEffect(() => subscribe(() => setState(getState()), []))
  return { state, dispatch, subscribe }
};
