import React from "react"
import Helmet from "react-helmet"
import config from "../../data/metaData"

const Meta = ({ title, description, img, keywords, url, type }) => {
    const metaTitle = title ? title : config.title
    const metaDescription = description || config.description
    const image = img || config.siteImage
    const siteUrl = url || config.siteUrl

    return (
        <Helmet>
            <html lang="en" />
            <meta charSet="utf-8" />
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={keywords} />
            <meta name="robots" content="INDEX,FOLLOW" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={image} />
            <meta property="og:type" content="Organization" />
            <meta property="og:url" content={siteUrl} />
            <link rel="canonical" href={siteUrl} />

            <script>
                {`
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "Name": "Lankanwedding.com",
                        "URL": "https://www.lankanwedding.com",
                        "headline":"Lankanwedding.com - Sri Lankan Community Matrimony for Sinhala, Tamil, Muslim and Burgher."
                        "contactPoint": [
                        {
                            "@type": "ContactPoint",
                            "telephone": "+94 (76) 832 1738",
                            "contactType": "Customer Service"
                        }
                        ],
                        "logo": "${config.logoSquare}",
                        "sameAs": [
                            "https://www.facebook.com/lankanweddingmatrimony,
                            "https://twitter.com/lankanwedding",
                            "https://www.instagram.com/lankanwedding/",
                            "https://www.youtube.com/channel/UCVgn_INVz1SsKLLsu7VcLXw"
                        ]
                `}
            </script>
            
        </Helmet>
    )
}

export default Meta
