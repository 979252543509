module.exports = {
    title: "lankanwedding.com - Sri Lankan Community Matrimony for Tamil, Sinhala and Muslim",
    subTitle:"lankanwedding.com - Sri Lankan Community Matrimony for Tamil, Sinhala and Muslim",
    description: 'The one and only matrimonial service for Sri Lankans. Search compatible partners by city, profession & community',
    keyWords: 'Find your match, partner search, matrimonial services, matrimonial sri lanka, bride search, matrimonial search, marriage, matrimonial sites, free matrimonial, srilankan matrimonial, life partner, looking for bride, shaadi matrimonial, matrimony, beautiful brides',
    author: 'Lankanwedding',
    siteUrl: 'https://lankanwedding.com',
    siteImage: 'https://lankanwedding.s3.eu-west-3.amazonaws.com/public/lankanwdiing_logo_1000x1000.png',
    logoSquare: 'https://lankanwedding.s3.eu-west-3.amazonaws.com/public/lankanwdiing_logo_1000x1000.png',
    logoVertical: 'https://lankanwedding.s3.eu-west-3.amazonaws.com/public/lankanwedding-vertical-logo.png',
    language: 'en'
}
