import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import ReCAPTCHA from 'react-google-recaptcha'
import inputField from '../InputFields/Input'
import textArea from '../InputFields/TextArea'
import { required, email } from '../../lib/validationAndWarning'
import { sendContactUsemail } from '../../reducers/contactUs'

const contactUs = ({ handleSubmit, sendContactUsemail }) => {
  const recaptchaRef = React.createRef()
  const [reCAPTCHA, setReCAPTCHA] = useState()

  const submitHandler = () => {
    if (reCAPTCHA) {
      sendContactUsemail(reCAPTCHA)
      recaptchaRef.current.reset()
    } else {
      alert("Recaptcha hasn't been checked")
    }
  }

  const onChange = value => {
    if (value) {
      setReCAPTCHA(value)
    }
  }

  const onRecaptchaError = error => {
    console.error('error =>', error)
  }

  return (
    <article id="contact-us">
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="container">
          <div className="section-headline centered margin-top-0 margin-bottom-35">
            <h3>Contact Us</h3>
          </div>
          <div className="row">
            <div className="col-xl-8">
              <section id="contact" className="margin-bottom-60">
                <h3 className="headline margin-top-15 margin-bottom-35">
                  Any questions? Feel free to contact us!
                </h3>

                <div className="border-box">
                  <div className="row">
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      component={inputField}
                      label="Your Name"
                      className="with-border"
                      placeholder="Your Name"
                      column="col-xl-6"
                      validate={[required]}
                    />

                    <Field
                      id="email"
                      name="email"
                      type="email"
                      component={inputField}
                      label="Your Email"
                      className="with-border"
                      placeholder="Your Email"
                      column="col-xl-6"
                      validate={[required, email]}
                    />
                  </div>

                  <div className="row">
                    <Field
                      id="subject"
                      name="subject"
                      type="text"
                      component={inputField}
                      label="Your Subject"
                      className="with-border"
                      placeholder="Your Message"
                      column="col-xl-12"
                      validate={[required]}
                    />

                    <Field
                      id="message"
                      name="message"
                      type="text"
                      component={textArea}
                      label="Your Message"
                      className="with-border"
                      placeholder="Your Message"
                      column="col-xl-12"
                      validate={[required]}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <ReCAPTCHA
                        sitekey={process.env.RECAPTCHA_SITE_KEY}
                        onChange={onChange}
                        onErrored={onRecaptchaError}
                        ref={recaptchaRef}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="float-right">
                        <button
                          type="submit"
                          className="button full-width button-sliding-icon ripple-effect"
                        >
                          Submit
                          <i className="icon-material-outline-arrow-right-alt" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="col-xl-4">
              <div
                className="contact-location-info margin-bottom-50 margin-bottom-78 "
                style={{ marginTop: 78 }}
              >
                <div className="contact-address">
                  <ul>
                    <li className="contact-address-headline">Our Office</li>
                    <li>Colombo, Sri Lanka</li>
                    <li>Phone: +94 (76) 832 1738(Viber, WhatsApp)</li>
                    <li>
                      <a href="#">info@lankanwedding.com</a>
                    </li>
                    <li>
                      <div className="freelancer-socials">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/lankanweddingmatrimony"
                              data-tippy-placement="top"
                              data-tippy
                              data-original-title="facebook"
                            >
                              <i className="icon-feather-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/lankanwedding"
                              data-tippy-placement="top"
                              data-tippy
                              data-original-title="Twitter"
                            >
                              <i className="icon-brand-twitter" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/lankanwedding"
                              data-tippy-placement="top"
                              data-tippy
                              data-original-title="instagram"
                            >
                              <i className="icon-feather-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/channel/UCVgn_INVz1SsKLLsu7VcLXw"
                              data-tippy-placement="top"
                              data-tippy
                              data-original-title="youtube"
                            >
                              <i className="icon-feather-youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </article>
  )
}

const InitializeFromStateForm = reduxForm({
  form: 'contactUsForm',
})(contactUs)

const mapStateToProps = state => ({
  initialValues: state.contactUs,
})

const mapDispatchToProps = {
  sendContactUsemail,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InitializeFromStateForm)
