import { useEffect, useCallback } from 'react';
import useStore from './useStore';
import { fetchUserProfile, deleteProfile } from '../reducers/profile'

export default  (id, uri) => {
    const { state, dispatch } = useStore();
    const { profile: { loading, userProfile } } = state;

    const execute = useCallback(() => {
        dispatch(fetchUserProfile(id));
    }, [])

     useEffect(() => {
        execute();
    }, [uri]);

    useEffect(() => {
        execute();
    }, [])

    return {
        dispatch, 
        loading,
        userProfile,
        deleteProfile
    }
};
