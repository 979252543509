import React, {useEffect,useState} from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../assets/styles/sass/style.scss'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import NavigationBar from '../components/common/NavigationBar';
import LoadingOverlay from '../components/common/LoadingOverlay';
import Modal from '../components/common/Modal';
import SignIn from '../components/Auth/SignIn';
import Notification from '../components/common/Notifiacation'
import { setLocationHistory } from '../reducers/controls'

const ignoreFooter = [
    '/app/login',
    '/app/privacy'
]

const Layout = (props) => {
    let { 
        children, 
        showLoading,
        uri,
        path,
        verificationToken,
        handleSetLocationHistory
    } = props;

    const [wrapperElements, setWrapperElements] = useState(null);
    const [isScreenNotFit, setIsScreenNotFit] = useState(false);
    
    useEffect(() => {
        handleSetLocationHistory({
            uri,
            path,
            verificationToken
        })
    }, [uri])


    useEffect(() => {
        if(wrapperElements !== null) {
            if(wrapperElements.clientHeight < window.screen.height) {
                setIsScreenNotFit(true);
            }
        }

    }, [wrapperElements, uri])

    return(
        <>
            <LoadingOverlay isActive={showLoading} />
            <Modal modalOwner="login">
                <SignIn/>
            </Modal>
            <Notification/>
            <Header />
            <div
                style={isScreenNotFit ? { minHeight: '91vh'} : {}}
                id="wrapper"
                ref={(wrapper) => setWrapperElements(wrapper) }
            >
                <NavigationBar {...props} />
                <div>
                    {children}
                </div>
            </div>
            
            {/* {!ignoreFooter.includes(uri) && <Footer /> } */}
            <MessengerCustomerChat
                pageId="104895997902967"
                appId="230791878219188"
            />
            <Footer />
        </>
    )

    

}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

const mapStateToProps = state => ({
    showLoading: state.controls.showLoading,
    auth: state.auth
})

const mapDispatchToProps = dispatch => {
    return {
        handleSetLocationHistory: history =>  dispatch(setLocationHistory(history)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout)

